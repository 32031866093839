<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
    <v-row dense>
      <v-col cols="12" class="pa-0">
        <v-card color="grey lighten-3">
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Helpful Links</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <loading-bar :value="loading"></loading-bar>
          <v-card-text>
            <v-data-iterator
              :items="links"
              disable-sort
              :search="search"
              :items-per-page="-1"
              hide-default-footer
            >
              <template v-slot:default="props">
                <v-row dense>
                  <v-col
                    v-for="item in props.items"
                    :key="item.to.name"
                    cols="12"
                    sm="6"
                    md="4"
                    xl="3"
                  >
                    <v-card class="fill-height flexcard clickable" @click.native="linkClick(item)">
                      <v-card-text class="pa-0 shrink">
                        <v-img
                          v-if="item.thumbnail"
                          :src="item.thumbnail"
                          :aspect-ratio="16/9"
                        >
                        </v-img>
                      </v-card-text>
                      <v-card-title>
                        {{item.title}}
                      </v-card-title>
                      <v-card-text class="grow">
                        {{item.description}}
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          color="red white--text"
                          fab small
                        >
                          <v-icon>fas fa-caret-right</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="650"
      max-width="90vw"
      max-height="90vh"
      transition="dialog-transition"
    >
      <div>
        <v-btn color="error white--text" class="mt-n2" small fab absolute right @click.stop="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
        <v-card>
          <v-card-text class="pa-3">
            <youtube
              v-if="selected"
              :videoId="selected.youtubeId"
              player-width="100%"
            ></youtube>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      links: [
        {
          title: 'VBL Ratings Explained',
          description: 'A full explanation of the Volleyball Life ratings program.',
          to: { name: 'ratings-explained' }
        },
        {
          title: 'What\'s My Volley Age',
          description: 'A simple calculator to tell you what age division you can play',
          to: { name: 'volley-age' }
        },
        {
          title: 'Bracket Builder',
          description: 'A simple tool to build sample brackets',
          to: { name: 'bracketbuilder' }
        },
        {
          title: 'Holiday Deals',
          description: 'A list of all our holiday deals',
          to: { name: 'holiday-ads' }
        }
      ],
      loading: false,
      search: null,
      selected: null,
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['subdomain'])
  },
  methods: {
    linkClick (link) {
      this.$router.push(link.to)
    }
  }
}
</script>
